<template>
    <dialog-box v-if="value" @close="$emit('input', false)" width="500px" :title="'Select users for ' + team.name">
        <div class="row-fluid">
            <div class="span12">
                <div style="margin-bottom: 8px; display: flex; align-items: center">
                    <search-input v-model="q" autofocus style="flex-grow: 1"/>
                    <label class="checkbox" style="margin-left: 16px">
                        <input type="checkbox" v-model="onlySelected"> Only show selected
                    </label>
                </div>
                <checkbox-list v-model="userIds" :options="users" class="list">
                    <template slot-scope="p">
                        <email-tag :user="p.item" no-close/>
                    </template>
                </checkbox-list>
            </div>
        </div>
        <template #buttons>
            <a class="btn cancel" @click="$emit('input', false)">Cancel</a>
            <a class="btn btn-primary" @click="ok" :class="{disabled: saving}">
                <span v-if="saving" class="spinner"/>
                <span v-else>Ok</span>
            </a>
        </template>
    </dialog-box>
</template>

<script>
    import DialogBox from '../../components/DialogBox'
    import CheckboxList from "../../components/inputs/CheckboxList";
    import EmailTag from "../../components/tags/EmailTag"
    import SearchInput from "../../components/inputs/SearchInput";
    import { teams } from "@/store/deprecated/Stores"
    import VuexStore from "@/store/vuex/VuexStore";
    import {mapState} from "vuex";

    export default {
        name: "EditTeamUsersDialog",
        store: VuexStore,

        components: {EmailTag, DialogBox, CheckboxList, SearchInput },

        props: {
            value: Boolean,
            team: Object
        },

        data() {
            return {
                userIds: this.team.userIds || [],
                saving: false,
                error: null,
                q: "",
                onlySelected: false
            }
        },

        computed: {
            ...mapState('accountUsers', { allAccountUsers: 'users' }),

            users() {
                let q = this.q.trim().toLowerCase()
                if (!q && !this.onlySelected) return this.allAccountUsers;
                return this.allAccountUsers.list.filter(u => {
                    if (this.onlySelected && this.userIds.indexOf(u.id) < 0) return false
                    return u.email.indexOf(q) >= 0 || u.firstName && u.firstName.toLowerCase().indexOf(q) >= 0 ||
                        u.lastName && u.lastName.toLowerCase().indexOf(q) >= 0
                })
            }
        },

        methods: {
            ok() {
                let payload = { userIds: this.userIds }
                this.saving = true
                let xhr = this.team.id ? teams.update(this.team, payload) : teams.create(payload)
                xhr.then(() => this.$emit('input', false))
                    .catch(e => this.error = "" + e )
                    .finally(() => this.saving = false )
            }
        }
    }
</script>

<style scoped>
    .be-tag ::v-deep {
        cursor: pointer;
    }

    .checkbox:hover .be-tag ::v-deep .be-email-tag__address {
        color: #aee15d !important;
    }

    .list {
        height: calc(70vh - 100px);
        overflow-y: auto;
    }
</style>