<template>
    <tr @click="editing = canEdit" :class="{editable: canEdit}">
        <td>{{team.name}}</td>
        <td>{{team.description}}</td>
        <td @click.stop="editingUsers = canEdit">
            <email-tag v-for="u in users" :key="u.id" :user="u" no-close/>
            <i v-if="canEdit" class="icon-plus"></i>
        </td>
        <EditTeamDialog v-if="canEdit && editing" v-model="editing" :team="team"/>
        <EditTeamUsersDialog v-if="canEdit && editingUsers" v-model="editingUsers" :team="team"/>
    </tr>
</template>

<script>
    import EditTeamDialog from "./EditTeamDialog"
    import EditTeamUsersDialog from "./EditTeamUsersDialog";
    import EmailTag from "../../components/tags/EmailTag"
    import {mapGetters, mapState} from "vuex";

    export default {
        name: "TeamRow",

        components: { EditTeamDialog, EmailTag, EditTeamUsersDialog },

        props: {
            team: Object,
            canEdit: Boolean
        },

        data() {
            return {
                editing: false,
                editingUsers: false
            }
        },

        computed: {
            ...mapState('accountUsers', { allAccountUsers: 'users' }),
            ...mapGetters('accountUsers', ['idToUser']),

            users() {
                if (!this.team.userIds) return [];
                if (!this.allAccountUsers) return null;
                let ans = [];
                this.team.userIds.forEach(id => {
                    let u = this.idToUser.get(id);
                    if (u) ans.push(u)
                });
                return ans;
            }
        }
    }
</script>

<style scoped>
    .editable td {
        cursor: pointer;
    }

    .editable:hover td {
        color: #aee15d;
    }

    .editable .be-tag ::v-deep {
        cursor: pointer;
    }
</style>