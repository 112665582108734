<template>
    <dialog-box v-if="value" @close="$emit('input', false)" width="500px"
                :title="(team.id ? 'Edit' : 'Create') + ' team'">
        <div>
            <div class="row-fluid">
                <div class="control-group span12">
                    <text-input v-model="name" required label="Name" style="width: 200px" ref="name"/>
                    <text-input style="margin-top: 10px" v-model="description" label="Description"/>
                    <div>

                    </div>
                </div>
            </div>

            <div v-if="error" class="row-fluid">
                <div class="span12">
                    <div class="alert">{{error}}</div>
                </div>
            </div>
        </div>
        <template #buttons>
            <a class="delete btn pull-left" @click="del" v-if="team.id">Delete</a>
            <a class="btn cancel" @click="$emit('input', false)">Cancel</a>
            <a class="btn btn-primary" @click="ok" :class="{disabled: saving}">
                <span v-if="saving" class="spinner"/>
                <span v-else>Ok</span>
            </a>
        </template>
    </dialog-box>
</template>

<script>
    import DialogBox from '../../components/DialogBox'
    import TextInput from "../../components/inputs/TextInput"
    import { teams } from "../../store/deprecated/Stores"

    export default {
        name: "EditTeamDialog",

        components: { DialogBox, TextInput },

        props: {
            value: Boolean,
            team: Object
        },

        data() {
            return {
                name: this.team.name,
                description: this.team.description,
                saving: false,
                error: null
            }
        },

        mounted() {
            this.$nextTick(() => this.$refs.name.focus())
        },

        methods: {
            ok() {
                let payload = { name: this.name, description: this.description }
                this.saving = true
                let xhr = this.team.id ? teams.update(this.team, payload) : teams.create(payload)
                xhr.then(() => this.$emit('input', false))
                    .catch(e => this.error = "" + e )
                    .finally(() => this.saving = false )
            },

            del() {
                if (!window.confirm("Are you sure you want to delete " + this.team.name + "?")) return
                this.saving = true
                teams.delete(this.team.id)
                    .then(() => this.$emit('input', false))
                    .catch(e => this.error = "" + e )
                    .finally(() => this.saving = false )
            }
        }
    }
</script>

<style scoped>

</style>